import React from 'react'
import './scroller.css';
export default function Scroller() {
  return (<>
    <div className="bar">
    <span className="bar_content">
    Technical events&nbsp;&#10070;&nbsp;Funtech events&nbsp;&#10070;&nbsp;Gaming events
    </span>
  </div>
    <div className='line'>
    &nbsp;&nbsp;&nbsp;&nbsp;
    </div>
    </>
  )
}
